import {
  MyNintendoGoldCoinIcon,
  Text,
  Skeleton,
} from '@nintendo-of-america/component-library';
import { useLocalizer } from '@nintendo-of-america/react-hooks';
import useGoldPointsMessage from './useGoldPointsMessage';
import s from './GoldPoints.module.css';

export const GoldPointsMessage = ({
  eligiblePoints,
  loading,
  newMessage,
  goldPoints,
}) => {
  const { text } = useLocalizer();

  if (loading) {
    return <Skeleton className={s.skeleton} width="100%" />;
  }

  if (newMessage === false) {
    return (
      <div className={s.eligiblePoints}>
        <MyNintendoGoldCoinIcon size={24} />
        <Text variant="legal">
          {text('Eligible for up to {0} Gold Points', {
            args: [
              <span className={s.boldText} key="goldPoints">
                {eligiblePoints}
              </span>,
            ],
          })}
        </Text>
      </div>
    );
  }

  return (
    <div className={s.goldPointsValue}>
      <MyNintendoGoldCoinIcon size={24} />
      <span className={s.text}>
        {goldPoints.showValue
          ? text('Your Gold Points: {0} = {1} discount', {
              args: [goldPoints.total, goldPoints.value],
            })
          : text('Earn up to {0} Gold Points', {
              args: [
                <span className={s.boldText} key="goldPoints">
                  {eligiblePoints}
                </span>,
              ],
            })}
      </span>
    </div>
  );
};

const GoldPoints = ({ eligiblePoints }) => {
  const { loading, newMessage, goldPoints } = useGoldPointsMessage();

  return (
    <GoldPointsMessage
      eligiblePoints={eligiblePoints}
      loading={loading}
      newMessage={newMessage}
      goldPoints={goldPoints}
    />
  );
};

export default GoldPoints;
